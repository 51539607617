import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class PageTitleStrategy extends TitleStrategy {
  private _baseTitle = '';

  constructor(private readonly title: Title) {
    super();
  }

  setBaseTitle(baseTitle: string) {
    this._baseTitle = baseTitle;
  }

  get baseTitle() {
    return this._baseTitle;
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`${this._baseTitle} - ${title}`);
    }
  }
}
